/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    overflow: hidden;
}

html {
    color: #fff;
}

/* Loading state */
html.is-loading .c-button {
    pointer-events: none;
    opacity: 0;
}

html.is-loading .c-button {
    opacity: 0;
    transform: scale(.5);
}

html:not(.is-loading) .c-spinner {
    opacity: 0;
    transform: scale(0);
}

/* Spinner loader */
.c-spinner {
    --spinner-size: 48px;

    z-index: 100;
    position: fixed;
    top: calc(50% - var(--spinner-size)/2);
    left: calc(50% - var(--spinner-size)/2);
    display: block;
    width: var(--spinner-size);
    height: var(--spinner-size);
    pointer-events: none;
    transition: all .3s ease-out;
}

.c-spinner span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border: 3px solid currentColor;
    opacity: 1;
    border-radius: 50%;
    animation: spinner 1.4s ease-in-out infinite;
}

.c-spinner span:nth-child(2) {
    animation-delay: -.7s;
}

@keyframes spinner {
    0% {
        opacity: 1;
        transform: scale(0);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

/* Canvas */
.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

/* Navigation */
.c-button {
    --button-size: 48px;

    position: fixed;
    top: calc(50% - var(--button-size)/2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--button-size);
    height: var(--button-size);
    font-family: sans-serif;
    font-weight: 700;
    font-size: 8px;
    color: inherit;
    text-transform: uppercase;
    background: transparent;
    border: 2px solid currentColor;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    transition: all .3s ease-out;
}

.c-button:hover {
    opacity: .4;
}

.c-button.-prev {
    left: 20px;
}

.c-button.-next {
    right: 20px;
}
